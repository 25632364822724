
































import { Inject } from 'inversify-props';
import { Component, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CarsAnalysisFiltersService, { CarsAnalysisFiltersServiceS } from '../cars-analysis-filters.service';
import { comparisonDays } from '../models/cars-analysis.model';

@Component({
    components: {
        CustomSelect,
        CustomMultiSelect,
    },
})
export default class CarRatesAnalysisComparison extends Vue {
    @Inject(CarsAnalysisFiltersServiceS) carsAnalysisFiltersService!: CarsAnalysisFiltersService;

    @Watch('comparisonType')
    async onOptionsChanged() {
        if (['diffDays'].includes(this.comparisonType)) {
            this.carsAnalysisFiltersService.comparisonValues = [comparisonDays[1]];
        } else {
            const compact = this.comparisonValuesItems.filter(item => item.name.toLowerCase() === 'compact')?.[0] || this.comparisonValuesItems?.[0] || null;
            const economy = this.comparisonValuesItems.filter(item => item.name.toLowerCase() === 'economy')?.[0] || this.comparisonValuesItems?.[1] || null;
            const items = [];
            if (compact) {
                items.push(compact);
            }
            if (economy) {
                items.push(economy);
            }
            this.carsAnalysisFiltersService.comparisonValues = items;
        }
    }

    get isMultiSelect(): boolean {
        if (['diffDays'].includes(this.comparisonType)) {
            return false;
        }
        return true;
    }

    get comparisonType() {
        return this.carsAnalysisFiltersService.comparisonKey;
    }

    set comparisonType(value: string) {
        if (value !== this.comparisonType) {
            this.carsAnalysisFiltersService.comparisonKey = value;
        }
    }

    get comparisonValue() {
        return this.carsAnalysisFiltersService.comparisonValues.length ? this.carsAnalysisFiltersService.comparisonValues[0].value : '';
    }

    set comparisonValue(value: string | number) {
        this.carsAnalysisFiltersService.setComparisonValue(value);
    }

    get multiComparisonValues() {
        return this.carsAnalysisFiltersService.comparisonValues;
    }

    set multiComparisonValues(values) {
        this.carsAnalysisFiltersService.setComparisonValues(values);
    }

    get comparisonTypeItems(): Item[] {
        return this.carsAnalysisFiltersService.filterList;
    }

    get comparisonValuesItems(): Item[] {
        const { currentFilterItems } = this.carsAnalysisFiltersService;
        return currentFilterItems;
    }

    comparisonValueTitle(selectedValue: number | string) {
        const findElement = this.comparisonValuesItems.find(element => element.value === selectedValue);
        return findElement ? findElement.name : '';
    }

    comparisonTypeTitle(selectedType: string) {
        const findElement = this.comparisonTypeItems.find(element => element.value === selectedType);
        return findElement ? findElement.name : 'diffDays';
    }
}
